<template>
  <v-row>
    <app-header />
    <div class="col-12">
      <LoopMatch :matchId="matchId" :scrollTo="scrollTo">
        <template #header="{ match }">
          <v-row>
            <v-col>
              <v-btn icon :to="getBackLink(match)" style="margin-top: 0px">
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <span>{{ $t("match.goBack") }}</span>
              <!-- <span>Match Details</span> -->
            </v-col>
          </v-row>
        </template>
      </LoopMatch>
    </div>
  </v-row>
</template>

<script>
import LoopMatch from "@/components/loop/LoopMatch.vue";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";

const user = constant.user;

export default {
  name: "LoopInfo",
  components: {
    LoopMatch,
  },
  computed: {
    ...mapGetters("user", { getUser: user.getters.getUser, isUserPartner: user.getters.isUserPartner }),

    matchId() {
      return this.$route.params.matchId;
    },
    scrollTo() {
      return this.$route.hash === "#questions" ? "questions" : null;
    },
  },
  methods: {
    getBackLink(match) {
      const { userId, searchId } = match;
      let link = "";
      if (this.isUserPartner) {
        link = `/clients/${userId}`;
      }
      link += `/loops/${searchId}/matches`;
      return link;
    },
  },
};
</script>

<style scoped>
.container {
  margin: 0;
}
</style>
